<template>
  <div
    class="bpmn-builder"
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中..."
  >
    <div
      :style="'width:100%;height:'+wrapHeight + 'px'"
      ref="canvas"
    ></div>
    <ul
      class="function-panel"
      v-if="isDev"
    >
      <li @click="previewProcessXML">预览XML</li>
      <li @click="telluData('nodeList', nodeList)">预览数据nodeList</li>
      <li @click="nodeList=[]">清空nodeList</li>
    </ul>
    <el-dialog
      title="预览"
      width="60%"
      :visible.sync="previewModelVisible"
      append-to-body
      destroy-on-close
    >
      <pre v-highlight="previewResult"><code></code></pre>
    </el-dialog>
    <el-drawer
      size="560px"
      class="drawer-ui4"
      :visible.sync="drawer"
      :with-header="false"
    >
      <div class="drawer-header">
        <ul class="tabs-list">
          <li
            :class="{'active': !drawerPanelIndex}"
            @click="drawerPanelIndex=0"
          >节点设置</li>
          <li
            :class="{'active': !drawerPanelIndex}"
            @click="showRulePanel"
          >表单权限</li>
        </ul>
        <span
          class="active-line"
          :style="'left:'+[24, 112][drawerPanelIndex]+'px'"
        ></span>
        <i
          class="el-icon-close fr pointer"
          @click="drawer=false"
        ></i>
      </div>
      <div
        class="drawer-body is-scroll-y"
        v-show="!drawerPanelIndex"
        :style="'height:'+(wrapHeight -77) + 'px'"
      >
        <div class="form-item">
          <div class="form-title">
            基础信息
          </div>
          <ul class="form-item">
            <li class="required">
              <span class="item-name">节点名称</span>
              <div class="native-input-solid">
                <input
                  type="text"
                  v-model="node.name"
                  :max="8"
                  @input="nodeNameLimit"
                  autocomplete="off"
                  placeholder="请输入节点名称"
                />
              </div>
            </li>
            <li class="required">
              <span class="item-name">节点事件</span>
              <el-select
                class="date-picker-sty disabled-sty"
                popper-class="w-block-select-down yellow-select-panel"
                filterable
                size="small"
                placeholder="请选择节点事件"
                v-model="node.event"
                disabled="disabled"
              >
                <el-option
                  v-for="item in nodeEventList"
                  :key="item"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </li>
          </ul>
        </div>
        <div
          class="form-item"
          v-if="node.event === '发起'"
        >
          <div class="form-title">
            节点发起设置
            <el-tooltip
              class="item"
              effect="dark"
              content="允许由谁提交工单，未设置时默认为所有人"
              placement="right"
            >
              <span>
                <i class="icon-help-tips"></i>
              </span>
            </el-tooltip>
          </div>
          <ul class="form-item">
            <li>
              <span class="item-name">发起人</span>
              <div
                class="native-input-solid"
                :title="node.handlerText"
                @click="handleMember(0)"
              >
                <input
                  type="text"
                  :value="node.handlerTextEllipsis"
                  maxlength="20"
                  autocomplete="off"
                  placeholder="请选择发起人"
                  disabled
                />
                <span class="icon-unfold"></span>
              </div>
            </li>
          </ul>
        </div>
        <div
          class="form-item"
          v-if="node.event === '办理'"
        >
          <div class="form-title">
            节点处理设置
            <el-tooltip
              class="item"
              effect="dark"
              content="设置本节点的处理人及抄送人，如需发起子流程，则处理人为子流程的发起者"
              placement="right"
            >
              <span>
                <i class="icon-help-tips"></i>
              </span>
            </el-tooltip>
          </div>
          <ul class="form-item">
            <li class="required">
              <span class="item-name">节点处理人</span>
              <div
                class="native-input-solid"
                :title="node.handlerText"
                @click="handleMember(1)"
              >
                <input
                  type="text"
                  :value="node.handlerTextEllipsis"
                  autocomplete="off"
                  placeholder=""
                  disabled
                />
                <span class="icon-unfold"></span>
              </div>
            </li>
            <li>
              <span class="item-name">节点抄送人</span>
              <div
                class="native-input-solid"
                :title="node.cclistText"
                @click="handleMember(2)"
              >
                <input
                  type="text"
                  :value="node.cclistTextEllipsis"
                  autocomplete="off"
                  placeholder=""
                  disabled
                />
                <span class="icon-unfold"></span>
              </div>
            </li>
          </ul>
          <div class="form-title">
            节点通知设置
            <el-tooltip
              class="item"
              effect="dark"
              content="使用邮件/短信，需对应处理人/抄送人绑定邮箱账号/手机；使用微信公众号，需关注【公众号名称】"
              placement="right"
            >
              <span>
                <i class="icon-help-tips"></i>
              </span>
            </el-tooltip>
          </div>
          <ul class="form-item">
            <li class="required">
              <span class="item-name">通知处理人方式</span>
              <el-checkbox-group
                v-model="node.handlerNoticeType"
                @change="tellu"
              >
                <el-checkbox
                  v-for="item in informList"
                  :label="item.value"
                  :key="item.value"
                  :disabled="item.disabled"
                >
                  {{item.label}}
                </el-checkbox>
              </el-checkbox-group>
            </li>
            <li class="required">
              <span class="item-name">抄送方式</span>
              <el-checkbox-group
                v-model="node.copyNoticeType"
                @change="tellu"
              >
                <el-checkbox
                  v-for="item in informList"
                  v-show="item.value === 0"
                  :label="item.value"
                  :key="item.value"
                  :disabled="item.disabled"
                >
                  {{item.label}}
                </el-checkbox>
              </el-checkbox-group>
            </li>
          </ul>
          <div class="form-title">
            节点时效设置
            <el-tooltip
              class="item"
              effect="dark"
              content="可设置当前节点的时效要求，并可启用提前通知及超时处理机制"
              placement="right"
            >
              <span>
                <i class="icon-help-tips"></i>
              </span>
            </el-tooltip>
          </div>
          <ul class="form-item">
            <li>
              <span class="item-name">
                <el-checkbox v-model="node.isNodeAging">
                  时效要求
                </el-checkbox>
              </span>
              <el-select
                class="date-picker-sty"
                popper-class="w-block-select-down yellow-select-panel"
                filterable
                size="small"
                placeholder="请选择"
                v-model="node.nodeAging"
                :class="{'disabled-sty': !node.isNodeAging}"
              >
                <el-option
                  v-for="item in timeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <!-- <div class="time-control" :class="{'disabled-sty': !node.isNodeAging}">
                <el-input-number
                  v-model="node.nodeAging"
                  :step="1"
                  :max="999"
                  :min="0"
                  step-strictly>
                </el-input-number>
                <el-select
                  class="date-picker-sty"
                  popper-class="w-block-select-down"
                  filterable
                  size="small"
                  placeholder="请选择时效要求单位"
                  v-model="node.nodeAgingUnit"
                >
                  <el-option
                    v-for="item in timeUnitList"
                    :key="item"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
              </div> -->
            </li>
            <li v-if="node.isNodeAging" class="sub-item">
              <span class="item-name" :class="{'item-active': node.isNodeAging}">
                <el-checkbox v-model="node.isFinalComingAging">
                  临期通知
                </el-checkbox>
              </span>
              <el-select
                class="date-picker-sty"
                popper-class="w-block-select-down yellow-select-panel"
                filterable
                size="small"
                placeholder="请选择节点事件"
                v-model="node.finalComingAging"
                :class="{'disabled-sty': !node.isFinalComingAging}"
              >
                <el-option
                  v-for="item in timeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </li>
            <li v-if="node.isNodeAging" class="sub-item">
              <span class="item-name long-li" :class="{'item-active': node.isNodeAging}">
                <el-checkbox v-model="node.isOvertimeHandle">
                  超时处理
                </el-checkbox>
              </span>
              <el-radio-group
                v-model="node.overtimeHandleType"
                :class="{'disabled-sty': !node.isOvertimeHandle}"
              >
                <el-radio :label="1">提醒处理人</el-radio>
                <el-radio :label="0">本节点自动完成</el-radio>
                <el-radio :label="2">
                  委派他人
                  <div
                    class="native-input-solid"
                    :class="{'disabled-sty':node.overtimeHandleType!==2}"
                    :title="node.overtimeHandlerName"
                    @click="handleMember(3)"
                  >
                    <input
                      type="text"
                      :value="node.overtimeHandlerName"
                      autocomplete="off"
                      placeholder=""
                      disabled
                    />
                    <span class="icon-unfold"></span>
                  </div>
                </el-radio>
              </el-radio-group>
            </li>
          </ul>
        </div>
        <div
          class="form-item"
          v-if="node.event === '子流程'"
        >
          <div class="form-title">
            节点处理设置
            <el-tooltip
              class="item"
              effect="dark"
              content="设置本节点的处理人及抄送人，如需发起子流程，则处理人为子流程的发起者"
              placement="right"
            >
              <span>
                <i class="icon-help-tips"></i>
              </span>
            </el-tooltip>
          </div>
          <ul class="form-item">
            <li class="required">
              <span class="item-name">
                调用子流程
              </span>
              <div class="native-input-solid">
                <el-cascader
                  popper-class="yellow-select-panel"
                  v-model="node.subConfigId"
                  :options="subProcessList"
                  :props="{ value: 'config',label: 'name', emitPath: false }"
                  @change="changeSubProcess"
                ></el-cascader>
                <span class="icon-unfold"></span>
              </div>
            </li>
            <li class="required">
              <span class="item-name">
                节点处理人
              </span>
              <div
                class="native-input-solid"
                :title="node.handlerText"
                @click="handleMember(4)"
              >
                <input
                  type="text"
                  :value="node.handlerText"
                  autocomplete="off"
                  placeholder=""
                  disabled
                />
                <span class="icon-unfold"></span>
              </div>
            </li>
            <li class="required">
              <span class="item-name">
                数据传递
              </span>
              <div
                class="native-input-solid"
                @click="setDataSend"
              >
                <input
                  type="text"
                  :value="
                    node.mapping.length>0 &&
                    node.mapping[0].sourceFieldId &&
                    node.mapping[0].targetFieldId?'已设置':''
                  "
                  autocomplete="off"
                  placeholder=""
                  disabled
                />
                <span class="icon-unfold"></span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div
        class="drawer-body is-scroll-y"
        v-show="drawerPanelIndex"
        :style="'height:'+(wrapHeight - 77) + 'px'"
      >
        <table
          class="authority-tbs"
          v-if="authorityList && authorityList.length"
        >
          <tr>
            <th>表单字段</th>
            <th>查看</th>
            <th>编辑</th>
          </tr>
          <tr
            v-for="(item, index) in authorityList"
            :key="item.fieldId"
          >
            <td>{{item.columnName}}</td>
            <td>
              <el-checkbox
                v-model="item.isDisplay"
                @change="changeRuleItem($event, 0, index)"
              ></el-checkbox>
            </td>
            <td>
              <el-checkbox
                v-model="item.isEditable"
                @change="changeRuleItem($event, 1, index)"
              ></el-checkbox>
            </td>
          </tr>
          <p v-if="authorityList === null || (authorityList && authorityList.length===0)">
            <br>
            暂无权限列表
          </p>
        </table>
      </div>
      <div class="drawer-footer">
        <span
          @click="handleSubmit"
          class="button-basic button-primary"
        >保存</span>
        <span
          @click="drawer=false"
          class="button-basic button-low"
        >取消</span>
      </div>
    </el-drawer>
    <el-dialog
      title="数据传递"
      :visible.sync="showDataSent"
      class="dialog-black"
      v-if="showDataSent"
    >
      <ul class="data-list">
        <li>
          <div class="item-main fl">主流程表单字段</div>
          <div class="item-sub fr">子流程表单字段</div>
        </li>
        <li
          v-for="(item, index) in node.dataSendList"
          :key="item.key"
        >
          <div class="item-main fl">
            <el-select
              class="date-picker-sty"
              popper-class="w-block-select-down yellow-select-panel"
              filterable
              size="small"
              placeholder="请选择字段"
              v-model="item.sourceFieldId"
              @change="changeMappingItem($event, 0, index)"
            >
              <el-option
                v-for="ele in dataFieldsMain"
                :key="ele.fieldId"
                :label="ele.columnName"
                :value="ele.fieldId"
                :disabled="ele.isDisabled"
              ></el-option>
            </el-select>
            <span class="extra-text">对应</span>
          </div>
          <div class="item-sub fr">
            <el-select
              class="date-picker-sty"
              popper-class="w-block-select-down yellow-select-panel"
              filterable
              size="small"
              placeholder="请选择字段"
              @change="changeMappingItem($event, 1, index)"
              v-model="item.targetFieldId"
            >
              <el-option
                v-for="ele in dataFieldsSub"
                :key="ele.fieldId"
                :label="ele.columnName"
                :value="ele.fieldId"
                :disabled="ele.isDisabled"
              ></el-option>
            </el-select>
            <div class="handle-btns">
              <span
                :class="{'disabled-sty':
                  index !== node.dataSendList.length-1 ||
                  node.dataSendList.length === dataFieldsMain.length ||
                  node.dataSendList.length === dataFieldsSub.length
                }"
                class="button-s-add"
                @click="handleFieldList('add', index)"
              ></span>
              <span
                :class="{'disabled-sty': node.dataSendList.length===1}"
                class="button-s-del"
                @click="handleFieldList('del', index)"
              ></span>
            </div>
          </div>
        </li>
      </ul>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <span
          @click="handleDataSentSubmit(1)"
          class="button-basic button-primary"
        >保存</span>
        <span
          @click="handleDataSentSubmit(0)"
          class="button-basic button-low"
        >取消</span>
      </span>
    </el-dialog>
    <setMembers
      v-if="memberPanelKey!==-1"
      :selfData.sync="dialogData"
      :tabKey.sync="memberPanelKey"
      :config.sync="memberConfig"
      @closeDialog="closeDialog"
      @comfirm="dialogConfirm"
      ref="setMemberPanel"
    />
    <preview-bpmn
      v-if="showPreview"
      :XMLString="XMLString"
      :nodeList="nodeList"
      :showShade="true"
      @closeThis="closeThis"
    ></preview-bpmn>
  </div>
</template>

<script>
import Bus from '@/utils/bus.js'
import BpmnModeler from 'bpmn-js/lib/Modeler'
import customTranslate from './transition'
import customPalette from './palette'
// import gridSnapping from './grid-snapping'
// import paletteEntries from './palette/config/paletteEntries'
// import customRenderer from './renderer'
// import modelingEntries from './modeling'
import api from '@/api'
// import xmlJs from 'xml-js'
import customContextPad from './contextPad'
// import bpmnAutoPlace from './autoPlace'
import setMembers from '@/components/setDialog'
import previewBpmn from './previewBpmn'
export default {
  name: 'bpmnBuilder',
  data () {
    return {
      XMLString: '',
      showPreview: false,
      shouldGetNewRule: false, // rule组件初始化获取一次，如果返回上一步操作了，那么再获取一次
      loading: false,
      isEdit: Number(this.$route.query.edit) || 0,
      bpmnModeler: '',
      previewType: 'xml',
      previewResult: '',
      previewModelVisible: false,
      drawerPanelIndex: 0,
      processData: [], // 流程节点的集合
      element: null, // 当前操作的节点
      nodeEventList: ['发起', '办理', '子流程'],
      timeUnitList: ['分钟', '小时'],
      timeList: [],
      informList: [
        {
          label: '站内信',
          value: 0,
          disabled: true
        },
        {
          label: '短信',
          value: 2,
          disabled: false
        }
      ],
      dataFieldsMain: [],
      dataFieldsSub: [],
      nodeList: [], // 维护bpmn的数据的数组
      memberIndex: 0,
      typeList: ['bpmn:StartEvent', 'bpmn:Task', 'bpmn:EndEvent', 'bpmn:SubProcess'],
      configId: this.$route.query.id,
      authorityList: [], // 权限列表
      node: { // 当前操作的节点的数据
        camundaFile: '',
        code: null, // 节点id
        name: null, // 节点名称
        event: null, // 节点事件，前端使用
        type: null, // 节点类型
        handler: [], // 发起人(真实数据)
        handlerText: '', // 发起人(显示)
        handlerTextEllipsis: '', // 发起人(显示，省略)
        cclist: [], // 节点抄送人(真实数据)
        cclistText: '', // 节点抄送人(显示)
        cclistTextEllipsis: '', // 节点抄送人(显示，省略)
        handlerNoticeType: [0], // 通知处理人方式
        copyNoticeType: [0], // 抄送方式
        isNodeAging: false, // 是否启用时效要求 提交到接口为1 0
        nodeAging: 5, // 时效要求数值
        nodeAgingUnit: 0, // 时效要求单位
        isFinalComingAging: false, // 是否启用临期通知
        finalComingAging: 5, // 临期通知数值
        finalComingAgingUnit: 0, // 临期通知单位
        isOvertimeHandle: false, // 是否启用超时处理
        overtimeHandleType: 1, // 超时处理选项index
        overtimeHandlerId: [], // 超时处理-委派他人
        overtimeHandlerName: '', // 超时处理-委派他人
        subConfigId: null, // 调用子流程
        rule: [],
        dataSendList: [
          {
            key: 0,
            id: 0,
            nodeId: 0,
            sourceColumnName: '',
            sourceConfigId: '',
            sourceFieldId: '',
            sourceTableName: '',
            targetColumnName: '',
            targetConfigId: '',
            targetFieldId: '',
            targetTableName: ''
          }
        ], // 数据传递
        mapping: [ // 数据传递内容
          {
            key: 0,
            id: 0,
            nodeId: 0,
            sourceColumnName: '',
            sourceConfigId: '',
            sourceFieldId: '',
            sourceTableName: '',
            targetColumnName: '',
            targetConfigId: '',
            targetFieldId: '',
            targetTableName: ''
          }
        ]
      },
      dataFieldListKey: 0,
      memberPanelKey: -1,
      showMemberPanel: false,
      showDataSent: false,
      dialogData: null,
      drawer: false
    }
  },
  components: {
    setMembers,
    previewBpmn
  },
  computed: {
    memberConfig () {
      return {
        configId: this.configId,
        subConfigId: 0
      }
    },
    isDev () {
      return process.env.NODE_ENV === 'development'
    },
    wrapHeight: () => {
      return document.documentElement.clientHeight - 88
    }
  },
  mounted () {
    window.onbeforeunload = function (e) {
      return true
    }
    this.init()
    this.getAuthority()
    this.getSubProcess()
    const list = [5, 10, 15, 30, 45, 1, 2, 3, 6, 12, 24, 48, 72]
    this.timeList = list.map((item, index) => {
      let unit = '分钟'
      if (index > 4) {
        unit = '小时'
      }
      return {
        label: item + unit,
        value: item
      }
    })
    Bus.$on('previewBpmn', () => {
      this.showPreview = true
    })
  },
  watch: {
    authorityList (newValue) {
      console.log('nuwValue', newValue)
    }
  },
  methods: {
    closeThis () {
      this.showPreview = false
    },
    showRulePanel () {
      this.drawerPanelIndex = 1
    },
    changeRuleItem (val, key, index) { // 点击CheckBox时赋值
      console.log('val', val)
      // const objKey = key?'isEditable':'isDisplay'
      if (key && val) { // 编辑为true时候，查看也为true
        this.authorityList[index].isDisplay = true
      }
      if (!key && !val) { // 查看为false时，编辑也为false
        this.authorityList[index].isEditable = false
      }
    },
    telluData (name, data) {
      console.log(name, data)
    },
    setDataSend () { // 拉起数据传递面板
      if (!this.node.subConfigId) {
        this.eleMsg(0, '请先选择调用子流程')
        return false
      }
      // 如果节点本来存有数据，那么数据传递列表取节点数据
      if (
        this.node.mapping &&
        this.node.mapping.length
      ) {
        // 清理数据传递中 主流程、子流程字段已经不存在的数据
        const fieldIdListMain = this.dataFieldsMain.map(item => {
          return item.fieldId
        })
        const fieldIdListSub = this.dataFieldsSub.map(item => {
          return item.fieldId
        })
        this.node.mapping = this.node.mapping.filter(item => {
          const flag = fieldIdListMain.indexOf(item.sourceFieldId) !== -1 && fieldIdListSub.indexOf(item.targetFieldId) !== -1
          return flag
        })
        this.node.dataSendList = this.node.mapping.map(item => {
          const obj = {}
          for (const key in item) {
            obj[key] = item[key]
          }
          return obj
        })
      }
      this.setDataSendSelectDisabled(0)
      this.setDataSendSelectDisabled(1)
      this.showDataSent = true
    },
    resetForm () {
      this.dataFieldListKey = 0
      this.node = {
        // 当前操作的节点的数据
        camundaFile: '',
        code: null, // 节点id
        name: null, // 节点名称
        event: null, // 节点事件，前端使用
        type: null, // 节点类型
        handler: [], // 发起人(真实数据)
        handlerText: '', // 发起人(显示)
        handlerTextEllipsis: '', // 发起人(显示，省略)
        cclist: [], // 节点抄送人(真实数据)
        cclistText: '', // 节点抄送人(显示)
        cclistTextEllipsis: '', // 节点抄送人(显示，省略)
        handlerNoticeType: [0], // 通知处理人方式
        copyNoticeType: [0], // 抄送方式
        isNodeAging: false, // 是否启用时效要求 提交到接口为1 0
        nodeAging: 5, // 时效要求数值
        nodeAgingUnit: 0, // 时效要求单位
        isFinalComingAging: false, // 是否启用临期通知
        finalComingAging: 5, // 临期通知数值
        finalComingAgingUnit: 0, // 临期通知单位
        isOvertimeHandle: false, // 是否启用超时处理
        overtimeHandleType: 1, // 超时处理选项index
        overtimeHandlerId: [], // 超时处理-委派他人
        overtimeHandlerName: '', // 超时处理-委派他人
        subConfigId: null, // 调用子流程
        rule: [],
        dataSendList: [ // 数据传递内容(暂时保存)
          {
            key: 0,
            id: 0,
            nodeId: 0,
            sourceColumnName: '',
            sourceConfigId: '',
            sourceFieldId: '',
            sourceTableName: '',
            targetColumnName: '',
            targetConfigId: '',
            targetFieldId: '',
            targetTableName: ''
          }
        ], // 数据传递
        mapping: [ // 数据传递内容
          {
            key: 0,
            id: 0,
            nodeId: 0,
            sourceColumnName: '',
            sourceConfigId: '',
            sourceFieldId: '',
            sourceTableName: '',
            targetColumnName: '',
            targetConfigId: '',
            targetFieldId: '',
            targetTableName: ''
          }
        ]
      }
    },
    dialogConfirm (data) {
      let list = []
      let text = ''
      let textEllipsis = ''
      list = data.map((item, i) => {
        text += item.name
        if (i < data.length - 1) {
          text += ','
        }
        return item
      })
      if (text.length > 20) {
        textEllipsis = text.slice(0, 20) + '...'
      } else {
        textEllipsis = text
      }
      // 0 发起节点 发起人
      // 1 活动节点 处理人
      // 2 活动节点 抄送人
      // 3 活动节点 委派他人
      // 4 子流程节点 节点处理人
      if (
        this.memberIndex === 0 ||
        this.memberIndex === 1 ||
        this.memberIndex === 4
      ) {
        this.node.handler = list
        this.node.handlerText = text
        this.node.handlerTextEllipsis = textEllipsis
      }
      if (this.memberIndex === 2) {
        this.node.cclist = list
        this.node.cclistText = text
        this.node.cclistTextEllipsis = textEllipsis
      }
      if (this.memberIndex === 3) {
        this.node.overtimeHandlerId = (list && list.length ? list[0].member : '')
        this.node.overtimeHandlerName = text
      }
    },
    getDataFromNodeItem () {
      let itemIndex = 0
      for (let i = 0; i < this.nodeList.length; i++) {
        const item = this.nodeList[i]
        if (item.code === this.node.code) {
          console.log('item', item)
          console.log('itemIndex', i)
          itemIndex = i
          i = this.nodeList.length
        }
      }
      return this.nodeList[itemIndex]
    },
    closeDialog () {
      this.memberPanelKey = -1
    },
    handleMember (key) {
      if (key === 4) {
        if (!this.node.subConfigId) {
          this.eleMsg(0, '请先选择调用子流程')
          return false
        }
      }
      this.memberIndex = key
      const keyList = [2, 0, 0, 3, 3]
      this.memberPanelKey = keyList[key]
      let list = []
      if (key === 0 || key === 1 || key === 4) {
        list = this.node.handler
      }
      if (key === 2) {
        list = this.node.cclist
      }
      if (key === 3) {
        list = this.node.overtimeHandlerId
      }
      if (list.length > 0) {
        console.log('list....++++', list)
        this.dialogData = list.map(item => {
          return item
        })
      } else {
        this.dialogData = []
      }
      console.log('this.dialogData11111111', this.dialogData)
      this.$nextTick(() => {
        this.$refs.setMemberPanel.handleTabs()
        this.$refs.setMemberPanel.open()
      })
    },
    setDataSendSelectDisabled (key) {
      const flag = key ? 'targetFieldId' : 'sourceFieldId'
      const list = key ? this.dataFieldsSub : this.dataFieldsMain
      const disabledList = []
      for (let i = 0; i < this.node.dataSendList.length; i++) {
        const item = this.node.dataSendList[i]
        disabledList.push(item[flag])
      }
      if (disabledList && disabledList.length === 0) {
        for (let i = 0; i < list.length; i++) {
          const item = list[i]
          item.isDisabled = false
        }
      } else {
        for (let i = 0; i < list.length; i++) {
          const item = list[i]
          if (disabledList.indexOf(item.fieldId) !== -1) {
            item.isDisabled = true
          } else {
            item.isDisabled = false
          }
        }
      }
    },
    changeMappingItem (id, key, index) {
      console.log('correct', id)
      let itemData = {}
      const item = this.node.dataSendList[index]
      const option = key ? this.dataFieldsSub : this.dataFieldsMain
      itemData = option.find(ele => {
        return ele.fieldId === id
      })
      if (!itemData) {
        return false
      }
      if (key) { // 0 主流程 1子流程
        item.targetColumnName = itemData.columnName
        item.targetConfigId = itemData.configId
        item.targetFieldId = itemData.fieldId
        item.targetTableName = itemData.tableName
      } else {
        item.sourceColumnName = itemData.columnName
        item.sourceConfigId = itemData.configId
        item.sourceFieldId = itemData.fieldId
        item.sourceTableName = itemData.tableName
      }
      this.setDataSendSelectDisabled(key)
    },
    handleDataSentSubmit (key) { // 保存数据传递
      // dataSendList 用于存放临时数据
      // mapping 用于保存后存放数据
      console.log('this.node.mapping', this.node.mapping)
      if (key) { // 保存
        for (let i = 0; i < this.node.dataSendList.length; i++) {
          const item = this.node.dataSendList[i]
          if (!item.sourceFieldId && !item.targetFieldId) {
            this.eleMsg(0, '请先选择数据')
            i = this.node.dataSendList.length
            return false
          }
          // item.nodeId = this.node.code
        }
        this.node.mapping = []
        this.node.mapping = this.node.dataSendList.map(item => {
          return item
        })
      } else { // 取消
        this.node.dataSendList = []
        if (this.node.mapping && this.node.mapping.length) {
          this.node.dataSendList = this.node.mapping.map(item => {
            const obj = {}
            for (const key in item) {
              obj[key] = item[key]
            }
            return obj
          })
        } else {
          this.node.dataSendList = [{
            key: 0,
            id: 0,
            nodeId: 0,
            sourceColumnName: '',
            sourceConfigId: '',
            sourceFieldId: '',
            sourceTableName: '',
            targetColumnName: '',
            targetConfigId: '',
            targetFieldId: '',
            targetTableName: ''
          }]
        }
      }
      this.showDataSent = false
    },
    handleFieldList (key, index) {
      if (key === 'add') {
        this.dataFieldListKey++
        const obj = {
          key: this.dataFieldListKey,
          id: 0,
          nodeId: 0,
          sourceColumnName: '',
          sourceConfigId: '',
          sourceFieldId: '',
          sourceTableName: '',
          targetColumnName: '',
          targetConfigId: '',
          targetFieldId: '',
          targetTableName: ''
        }
        this.node.dataSendList.splice(index + 1, 0, obj)
      }
      if (key === 'del') {
        this.node.dataSendList.splice(index, 1)
        this.setDataSendSelectDisabled(0)
        this.setDataSendSelectDisabled(1)
      }
    },
    tellu (value) {
      console.log('value', value)
    },
    handleProperties (element, extensionList) {
    },
    handleXMLData (item, value) {
      const modeling = this.bpmnModeler.get('modeling')
      const params = {}
      params[item] = value
      modeling.updateProperties(this.element, params)
    },
    getAuthority (key) { // key:1 时为返回过上一步的场景
      const params = {
        configId: this.configId,
        nodeId: 0 // 只用来获取新建的节点权限，如已有数据则在节点接口返回
        // nodeId: this.isEdit ? (this.node.id || 0) : 0 // 新增的时候传0，所有节点的权限都为空
      }
      this.axios.get(api.getFlowConfigFieldRule, { params: params }).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          this.authorityList = resData.map(item => {
            item.isDisplay = true
            item.isEditable = true
            !item.nodeId && (item.nodeId = this.node.id)
            return item
          })
          if (key) { // 存在key时，处理节点数据（点击打开的）
            const nodeItem = this.getDataFromNodeItem() || {} // 从nodeList 中获取当前数据的引用
            if (nodeItem.rule && nodeItem.rule.length) { // 如果存在rule，那么取已勾选的填入view列表
              const checkedList = nodeItem.rule.filter(e0 => { // 获取已选列表
                return e0.isDisplay
              })
              if (checkedList.length) { // 存在已选列表，则勾选指定项
                checkedList.forEach(e0 => {
                  this.authorityList.forEach(e1 => {
                    if (e0.fieldId === e1.fieldId) {
                      e1.isDisplay = !!e0.isDisplay
                      e1.isEditable = !!e0.isEditable
                    }
                  })
                })
              }
            }
          }
        }
        this.loading = false
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    nodeNameLimit () {
      if (this.node.name.length > 8) {
        this.node.name = this.node.name.slice(0, 8)
      }
    },
    changeSubProcess () {
      this.node.handler = []
      this.node.handlerText = ''
      this.node.dataSendList = [{
        key: 0,
        id: 0,
        nodeId: 0,
        sourceColumnName: '',
        sourceConfigId: '',
        sourceFieldId: '',
        sourceTableName: '',
        targetColumnName: '',
        targetConfigId: '',
        targetFieldId: '',
        targetTableName: ''
      }]
      this.node.mapping = []
      this.getField(1, this.node.subConfigId)
      this.$emit('update:config', this.memberConfig)
      for (let i = 0; i < this.dataFieldsSub.length; i++) {
        const item = this.dataFieldsSub[i]
        item.isDisabled = false
      }
      for (let i = 0; i < this.dataFieldsMain.length; i++) {
        const item = this.dataFieldsMain[i]
        item.isDisabled = false
      }
    },
    getField (key, id) {
      const params = {
        configId: id
      }
      this.axios.get(api.getFlowConfigField, { params: params }).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          if (key) {
            this.dataFieldsSub = resData
          } else {
            this.dataFieldsMain = resData
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    handleSubmit () {
      if (this.node.name === '') {
        this.eleMsg(0, '节点名称尚未填写')
        return
      }
      if (this.node.name.length > 8) {
        this.eleMsg(0, '节点名称字数最多8个字符')
        return
      }
      const nodeItem = this.getDataFromNodeItem()
      nodeItem.name = this.node.name || ''
      this.handleXMLData('name', this.node.name)
      if (this.node.type === 'bpmn:StartEvent') {
        if (this.node.handler) {
          nodeItem.handler = this.node.handler.map(item => {
            item.configId = this.configId
            return item
          })
          nodeItem.handlerText = this.node.handlerText
          nodeItem.handlerTextEllipsis = this.node.handlerTextEllipsis
        }
      }
      if (this.node.type === 'bpmn:Task') {
        if (this.node.handler.length === 0) {
          this.eleMsg(0, '存在活动节点未设置节点处理人')
          return false
        }
        if (
          this.node.isOvertimeHandle &&
          this.node.overtimeHandleType === 2 &&
          this.node.overtimeHandlerId &&
          this.node.overtimeHandlerId.length === 0
        ) {
          console.log('this.node', this.node)
          this.eleMsg(0, this.node.name + '选择了超时处理中的委派他人，但未设置人员')
          return false
        }
        nodeItem.handler = this.node.handler.map(item => {
          item.configId = this.configId
          // item.memberType = item.type
          // item.member = item.id
          return item
        })
        nodeItem.handlerText = this.node.handlerText
        nodeItem.handlerTextEllipsis = this.node.handlerTextEllipsis
        if (this.node.cclist && this.node.cclist.length) {
          nodeItem.cclist = this.node.cclist
        }
        nodeItem.cclistText = this.node.cclistText
        nodeItem.cclistTextEllipsis = this.node.cclistTextEllipsis
        nodeItem.handlerNoticeType = this.node.handlerNoticeType.join(',')
        nodeItem.copyNoticeType = this.node.copyNoticeType.join(',')
        nodeItem.isNodeAging = this.node.isNodeAging ? 1 : 0
        if (this.node.isNodeAging) {
          nodeItem.nodeAging = this.node.nodeAging
          if ([5, 10, 15, 30, 45].indexOf(this.node.nodeAging) !== -1) {
            nodeItem.nodeAgingUnit = 0
          } else {
            nodeItem.nodeAgingUnit = 1
          }
        }
        nodeItem.isFinalComingAging = this.node.isFinalComingAging ? 1 : 0
        if (this.node.isFinalComingAging) {
          nodeItem.finalComingAging = this.node.finalComingAging
          nodeItem.finalComingAgingUnit = 0
        }
        nodeItem.isOvertimeHandle = this.node.isOvertimeHandle ? 1 : 0
        if (this.node.isOvertimeHandle) {
          nodeItem.overtimeHandleType = this.node.overtimeHandleType
          if (nodeItem.overtimeHandleType === 2) {
            nodeItem.overtimeHandlerId = this.node.overtimeHandlerId
          }
        }
      }
      if (this.node.type === 'bpmn:SubProcess') {
        if (this.node.handler.length === 0) {
          this.eleMsg(0, '尚未设置节点处理人')
          return false
        }
        if (this.node.mapping.length === 0) {
          this.eleMsg(0, '尚未设置数据传递')
          return false
        }
        if (!this.node.subConfigId) {
          this.eleMsg(0, '尚未设置调用子流程')
          return false
        }
        if (
          this.node.mapping &&
          this.node.mapping.length > 0 &&
          this.node.mapping[0].sourceFieldId === '' &&
          this.node.mapping[0].targetFieldId === ''
        ) {
          this.eleMsg(0, '尚未设置数据传递')
          return false
        }
        nodeItem.handler = this.node.handler.map(item => {
          item.configId = this.configId
          // item.memberType = item.type
          // item.member = item.id
          return item
        })
        nodeItem.handlerText = this.node.handlerText
        nodeItem.subConfigId = this.node.subConfigId
        nodeItem.mapping = this.node.mapping.map(item => {
          return item
        })
      }
      if (this.authorityList && this.authorityList.length) {
        nodeItem.rule = this.authorityList.map(item => {
          const obj = {}
          for (const key in item) {
            obj[key] = item[key]
          }
          return obj
        })
      }
      this.drawer = false
      this.eleMsg(1, '保存成功')
    },
    nodeInitData (num) {
      const list = [
        {
          handler: [],
          rule: []
        },
        {
          handler: [],
          cclist: [],
          // 处理人通知方式: 0 站内信; 1 邮件; 2 短信; 3 微信公众号 (',' 分割) ,
          handlerNoticeType: '',
          // 抄送人通知方式: 0 站内信; 1 邮件; 2 短信; 3 微信公众号 (',' 分割) ,
          copyNoticeType: '',
          isNodeAging: 0, // 是否控制节点审核时效: 0否, 1是 ,
          nodeAging: 0, // 当is_node_aging=1时启用录入具体的数值, 结合单位使用 ,
          nodeAgingUnit: 0, // 当is_node_aging=1时审核时效的单位, 审核时效使用: 0分钟, 1小时 ,
          isFinalComingAging: 0, // 是否控制节点审核临期时效; 0否, 1是 ,
          finalComingAging: 0, // is_final_coming_aging=1时生效, 临期时效值, 结合单位使用
          finalComingAgingUnit: 0, // 临期时效的单位, 临期时效使用: 0分钟, 1小时 ,
          isOvertimeHandle: 0, // 是否启用超时处理: 0不启用, 1启用 ,
          overtimeHandleType: 1, // 超时处理方式: 0自动流转下一节点, 1提醒处理人, 2委派他人 ,
          overtimeHandlerId: 0, // 超时处理人 , 只有一个
          rule: []
        },
        {
          subConfigId: '', // 子流程ID
          mapping: [ // 流程配置字段映射
            // {
            //   id: 0,
            //   sourceColumnName: '', // 来源字段名
            //   sourceConfigId: 0, // 来源流程ID
            //   sourceFieldId: 0, // 来源字段ID ,
            //   sourceTableName: '', // 来源表 ,
            //   targetColumnName: '', // 目标字段名 ,
            //   targetConfigId: 0, // 目标流程id ,
            //   targetFieldId: 0, // 目标字段ID ,
            //   targetTableName: '' // 目标表
            // }
          ],
          rule: []
        },
        {
        }
      ]
      return list[num]
    },
    previewProcessXML () {
      this.bpmnModeler.saveXML({ format: true }).then(({ xml }) => {
        this.previewResult = xml
        this.previewType = 'xml'
        this.previewModelVisible = true
      })
    },
    eleMsg (type, msg) {
      this.$message({
        type: ['warning', 'success'][type],
        message: msg
      })
    },
    submitProcess () {
      // 开始、结束节点必须有且仅允许有一个，活动节点必须有一个
      const list = ['bpmn:StartEvent', 'bpmn:Task', 'bpmn:EndEvent']
      for (let i = 0; i < list.length; i++) { // 检查bpmn内必选元素情况
        const ele = list[i]
        const res = this.nodeList.filter((item) => {
          return item.type === ele
        })
        switch (i) {
          case 0:
            if (res.length === 0) {
              this.eleMsg(0, '流程图缺少开始节点')
              i = 3
            } else if (res.length > 1) {
              this.eleMsg(0, '流程图只允许有一个开始节点')
              i = 3
            }
            break
          case 1:
            if (res.length === 0) {
              const resOther = this.nodeList.filter((item) => {
                return item.type === 'bpmn:SubProcess'
              })
              if (resOther.length === 0) {
                this.eleMsg(0, '流程图缺少流程节点')
                i = 3
              }
            }
            break
          case 2:
            if (res.length === 0) {
              this.eleMsg(0, '流程图缺少结束节点')
              i = 3
            } else if (res.length > 1) {
              this.eleMsg(0, '流程图只允许有一个结束节点')
              i = 3
            }
            break
          default:
            break
        }
        if (i === 3) {
          return
        }
      }
      for (let i = 0; i < this.nodeList.length; i++) { // 检查元素设置属性情况
        const item = this.nodeList[i]
        if (item.type === 'bpmn:StartEvent') {
          if (item.name === '') {
            this.eleMsg(0, '开始节点尚未设置节点名称')
            i = this.nodeList.length
            return false
          }
        }
        if (item.type === 'bpmn:Task') {
          if (item.name === '') {
            this.eleMsg(0, '存在活动节点未设置节点名称')
            i = this.nodeList.length
            return false
          }
          if ((item.handler && item.handler.length === 0) || !item.handler) {
            this.eleMsg(0, '存在活动节点未设置节点处理人')
            i = this.nodeList.length
            return false
          }
          item.isNodeAging = item.isNodeAging ? 1 : 0
          item.isFinalComingAging = item.isFinalComingAging ? 1 : 0
          item.isOvertimeHandle = item.isOvertimeHandle ? 1 : 0
        }
        if (item.type === 'bpmn:SubProcess') {
          if (!item.name) {
            this.eleMsg(0, '存在子流程节点未设置节点名称')
            i = this.nodeList.length
            return false
          }
          if ((item.handler && item.handler.length === 0) || !item.handler) {
            this.eleMsg(0, '存在子流程节点未设置节点处理人')
            i = this.nodeList.length
            return false
          }
          if (!item.subConfigId) {
            console.log('itemmmmm', item)
            this.eleMsg(0, '存在子流程节点未设置调用子流程')
            i = this.nodeList.length
            return false
          }
          if (
            item.mapping &&
            item.mapping.length > 0 &&
            item.mapping[0].sourceFieldId === '' &&
            item.mapping[0].targetFieldId === ''
          ) {
            this.eleMsg(0, '存在子流程节点未设置数据传递')
            i = this.nodeList.length
            return false
          }
        }
        if (item.rule) {
          item.rule = item.rule.map(item => {
            item.isDisplay = item.isDisplay ? 1 : 0
            item.isEditable = item.isEditable ? 1 : 0
            return item
          })
        }
      }
      this.loading = true
      const params = {
        configId: this.configId,
        camundaFile: this.previewResult,
        flowNodeList: this.nodeList.map(item => {
          if (item.rule && item.rule.length === 0 && item.type !== 'bpmn:EndEvent') {
            item.rule = this.authorityList.map(item1 => {
              item1.isDisplay = item1.isDisplay ? 1 : 0
              item1.isEditable = item1.isEditable ? 1 : 0
              return item1
            })
          }
          return item
        })
      }
      this.bpmnModeler.saveXML({ format: true }).then(({ xml }) => {
        params.camundaFile = xml
        this.axios.post(api.saveFlowNode, params).then(res => {
          if (res.data.code === 0) {
            this.eleMsg(1, res.data.msg)
            this.$router.push({
              name: 'workSetting'
            })
          }
          this.loading = false
        }, rej => {
          console.log(rej)
          this.loading = false
        }).catch(err => {
          console.log(err)
          this.loading = false
        })
      })
    },
    setInitData (type) {
      let tempObj = {}
      switch (type) {
        case 'bpmn:StartEvent':
          tempObj = this.nodeInitData(0)
          break
        case 'bpmn:Task':
          tempObj = this.nodeInitData(1)
          break
        case 'bpmn:SubProcess':
          tempObj = this.nodeInitData(2)
          break
        case 'bpmn:EndEvent':
          tempObj = this.nodeInitData(3)
          break
        default:
          break
      }
      if (tempObj.type !== 'bpmn:EndEvent') {
        tempObj.rule = [ // 流程配置字段权限
          // {
          //   columnName: '', // 字段名
          //   fieldId: '', // 字段Id
          //   id: 0, // 权限ID
          //   isDisplay: 0, // 是否显示: 0否, 1是
          //   isEditable: 0, // 是否可以编辑: 0否, 1是
          //   tableName: '' // 表名
          // }
        ]
      }
      tempObj.isNew = true
      return tempObj
    },
    isWantedEle (key) {
      let res = false
      if (
        key === 'bpmn:StartEvent' ||
        key === 'bpmn:Task' ||
        key === 'bpmn:EndEvent' ||
        key === 'bpmn:SubProcess'
      ) {
        res = true
      }
      return res
    },
    handleProcessList () {
      const elementRegistry = this.bpmnModeler.get('elementRegistry')
      const nodeList = elementRegistry.getAll().filter((item) => {
        return this.isWantedEle(item.type)
      })
      return nodeList
    },
    getSubProcess () { // 获取子流程列表
      const params = {
        projectId: this.$route.query.projectId,
        config: this.configId
      }
      this.axios.get(api.getFlowConfigCascadeList, { params: params }).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          this.subProcessList = resData
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    initSuccess () { // 初始化bpmn后，添加交互事件，click中的拉起抽屉的逻辑比较多
      const that = this
      const eventBus = that.bpmnModeler.get('eventBus') // 事件总线
      const elementRegistry = that.bpmnModeler.get('elementRegistry') // 元素注册表（用于追踪元素，注入事件总线）
      const keyEleList = ['bpmn:StartEvent', 'bpmn:Task', 'bpmn:SubProcess']
      that.bpmnModeler.get('canvas').zoom('fit-viewport', 'auto')
      const modeling = that.bpmnModeler.get('modeling')
      console.log('modeling', modeling)
      eventBus.on('element.dblclick', 3000, e => {
        return false
      })
      // const allElementList = elementRegistry.getAll()
      // allElementList.forEach(e => {
      //   if (e.type === 'bpmn:StartEvent' || e.type === 'bpmn:EndEvent') {
      //     modeling.updateLabel(
      //       e,
      //       e.businessObject.name,
      //       { x: e.x - 6, y: e.y + 42, width: 43, height: 14 }
      //     )
      //   }
      // })
      that.bpmnModeler.on('autoPlace', 1500, e => {
        console.log('autoPlace.e', e)
      })
      that.bpmnModeler.on('element.click', e => {
        const item = e.element
        const shape = item ? elementRegistry.get(item.id) : e.shape
        if ( // 如果是这三种类型以外的元素，那么不拉起抽屉
          keyEleList.indexOf(shape.type) === -1
        ) {
          return
        }
        this.authorityList.forEach(e => {
          e.isDisplay = true
          e.isEditable = true
        })
        this.memberPanelKey = -1 // 选人组件需要
        this.drawerPanelIndex = 0 // 0节点设置 1表单权限，每次拉起恢复节点设置面板
        this.resetForm() // 重置node
        const businessObject = JSON.parse(JSON.stringify(shape.businessObject))
        this.element = shape // 用来设置xml里面的属性(主要是设置name)
        // 以下节点设置基本属性
        this.node.code = businessObject.id
        this.node.name = businessObject.name || ''
        this.node.type = businessObject.$type
        const nodeItem = this.getDataFromNodeItem() || {} // 从nodeList 中获取当前数据的引用
        this.node.id = nodeItem.id
        if (this.shouldGetNewRule) { // 需要更新rule option时，在getAuthority() 处理，param传1
          this.getAuthority(1)
          this.shouldGetNewRule = false
        } else { // 无需更新时，检查rule是否为空，为空则填充内容
          console.log('nodeItem', nodeItem)
          console.log('authorityList', this.authorityList)
          if (nodeItem.rule && nodeItem.rule.length) {
            // const checkedList = nodeItem.rule.filter(e0 => {
            //   return e0.isDisplay
            // })
            const checkedList = nodeItem.rule.map(item => {
              return item
            })
            console.log('checkedList', checkedList)
            if (checkedList.length) {
              checkedList.forEach(e0 => {
                this.authorityList.forEach(e1 => {
                  if (e0.fieldId === e1.fieldId) {
                    e1.isDisplay = !!e0.isDisplay
                    e1.isEditable = !!e0.isEditable
                    e1.nodeId = item.nodeId
                  }
                })
              })
            }
          }
        }
        if (this.node.type === 'bpmn:StartEvent') { // 开始节点属性
          this.node.event = '发起'
          if (nodeItem.handler) {
            this.node.handler = nodeItem.handler.map(item => {
              return item
            })
          }
          // handlerText、handlerTextEllipsis用于回显
          this.node.handlerText = nodeItem.handlerText
          nodeItem.handlerTextEllipsis && (this.node.handlerTextEllipsis = nodeItem.handlerTextEllipsis)
        }
        if (this.node.type === 'bpmn:Task') { // 活动节点属性
          this.node.event = '办理'
          if (nodeItem.handler) {
            this.node.handler = nodeItem.handler.map(item => {
              return item
            })
          }
          this.node.handlerText = nodeItem.handlerText
          nodeItem.handlerTextEllipsis && (this.node.handlerTextEllipsis = nodeItem.handlerTextEllipsis)
          if (nodeItem.cclist) {
            this.node.cclist = nodeItem.cclist.map(item => {
              return item
            })
          }
          this.node.cclistText = nodeItem.cclistText
          nodeItem.cclistTextEllipsis && (this.node.cclistTextEllipsis = nodeItem.cclistTextEllipsis)
          if (nodeItem.handlerNoticeType) {
            this.node.handlerNoticeType = nodeItem.handlerNoticeType.split(',').map(item => {
              return Number(item)
            })
          }
          if (nodeItem.copyNoticeType) {
            this.node.copyNoticeType = nodeItem.copyNoticeType.split(',').map(item => {
              return Number(item)
            })
          }
          this.node.isNodeAging = !!nodeItem.isNodeAging
          this.node.nodeAging = nodeItem.nodeAging || 5
          this.node.isFinalComingAging = !!nodeItem.isFinalComingAging
          this.node.finalComingAging = nodeItem.finalComingAging || 5
          this.node.isOvertimeHandle = !!nodeItem.isOvertimeHandle
          this.node.overtimeHandleType = nodeItem.overtimeHandleType
          this.node.overtimeHandlerId = nodeItem.overtimeHandlerId
          this.node.overtimeHandlerName = nodeItem.overtimeHandlerName
        }
        if (this.node.type === 'bpmn:SubProcess') {
          this.node.event = '子流程'
          if (nodeItem.handler) {
            this.node.handler = nodeItem.handler.map(item => {
              return item
            })
            this.node.handlerText = nodeItem.handlerText
            this.node.subConfigId = nodeItem.subConfigId || 0
            this.node.mapping = [] // 数据传递列表
            if (nodeItem.mapping && nodeItem.mapping.length > 0) {
              this.node.mapping = nodeItem.mapping.map(item => {
                return item
              })
            }
          }
          this.getField(0, this.configId) // 获取主流程字段列表
          // 如果已设置子流程，那么获取子流程字段列表；否则在选择子流程时获取
          this.node.subConfigId && this.getField(1, this.node.subConfigId)
        }
        this.drawer = true
      })
      that.bpmnModeler.on('element.changed', e => {
        // console.log('element.changed.e', e)
        that.bpmnModeler.saveXML({ format: true }).then(({ xml }) => {
          that.XMLString = xml
        })
      })
      that.bpmnModeler.on('shape.changed', e => {
        // console.log('shape.changed.e', e)
      })
      const contextPad = that.bpmnModeler.get('contextPad')
      let timerClosePad = null
      let newElementCreate = false
      eventBus.on('element.out', e => {
        if (keyEleList.indexOf(e.element.type) !== -1) {
          timerClosePad = setTimeout(() => {
            contextPad.close()
          }, 500)
        } else {
          clearTimeout(timerClosePad)
          timerClosePad = null
        }
      })
      eventBus.on('element.hover', e => {
        // console.log('element.hover')
        const item = e.element
        if (
          [
            'bpmn:StartEvent',
            'bpmn:Task',
            'bpmn:EndEvent',
            'bpmn:ParallelGateway',
            'bpmn:ExclusiveGateway',
            'bpmn:SubProcess'
          ].indexOf(item.type) !== -1) {
          newElementCreate = false
          contextPad.open(item)
        }
        if (e.element.type === 'bpmn:Process' && !timerClosePad && !newElementCreate) {
          timerClosePad = setTimeout(() => {
            contextPad.close()
          }, 500)
        }
      })
      // eventBus.on('contextPad.create', e => {
      //   console.log('e', e)
      // })
      that.bpmnModeler.on('shape.removed', e => { // 每次删除shape，都从nodeList中删掉该shape的数据
        console.log('removed.e', e)
        const item = e.element.businessObject
        if (this.isWantedEle(item.$type)) {
          this.nodeList = this.nodeList.filter((item) => {
            return item.code !== e.element.businessObject.id
          })
          console.log('this.nodeList', this.nodeList)
        }
        const list = this.handleProcessList()
        console.log('list', list)
        if (!list.length) {
          this.nodeList = []
        }
      })
      that.bpmnModeler.on('shape.added', e => {
        // console.log('shape.added.e', e)
        newElementCreate = true
        const item = e.element.businessObject
        const shape = e.element ? elementRegistry.get(e.element.id) : e.shape
        let tempObj = this.setInitData(item.$type)
        tempObj = Object.assign(tempObj, {
          code: item.id,
          type: e.element.type
        })
        const elementList = [
          'bpmn:StartEvent',
          'bpmn:Task',
          'bpmn:SubProcess',
          'bpmn:EndEvent'
        ]
        const modeling = this.bpmnModeler.get('modeling')
        if (elementList.indexOf(tempObj.type) !== -1) {
          this.nodeList.push(tempObj)
          if (tempObj.type === 'bpmn:EndEvent') {
            this.$nextTick(() => {
              modeling.updateProperties(shape, { name: '结束节点' })
            })
          }
          if (tempObj.type === 'bpmn:StartEvent') {
            this.$nextTick(() => {
              modeling.updateProperties(shape, { name: '开始节点' })
            })
          }
        }
      })
    },
    init () { // 初始化bpmn
      this.loading = true
      // 去除默认工具栏start
      const modules = BpmnModeler.prototype._modules
      const index = modules.findIndex(it => it.paletteProvider)
      modules.splice(index, 1)
      // 去除默认工具栏end
      this.bpmnModeler = new BpmnModeler({
        // paletteEntries,
        container: this.$refs.canvas,
        additionalModules: [
          { translate: ['value', customTranslate] },
          // gridSnapping,
          // bpmnAutoPlace,
          // modelingEntries,
          customContextPad,
          customPalette
          // customRenderer
        ]
      })
      console.log('bpmnModeler', this.bpmnModeler)
      const that = this
      let setNewFlowFlag = true // 是否新建的流程
      const setData = () => {
        try {
          const warnings = async function () {
            await that.bpmnModeler.importXML(that.XMLString)
            setNewFlowFlag && newFlowSet() // 如果是新建的流程，那么需要赋值初始数据
            that.initSuccess() // 初始化bpmn后添加交互事件
          }
          warnings()
          console.log('rendered')
        } catch (err) {
          console.log('error rendering', err)
        }
      }
      const newFlowSet = function () { // 新建或数据不全时才执行
        const list = that.handleProcessList() // 获取处理的item
        that.nodeList = list.map(item => {
          let tempObj = that.setInitData(item.type)
          tempObj = Object.assign(tempObj, {
            code: item.id,
            name: item.businessObject.name,
            type: item.type
          })
          return tempObj
        })
      }
      this.nodeList = []
      that.XMLString = `<?xml version="1.0" encoding="UTF-8"?>
        <bpmn:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" xmlns:di="http://www.omg.org/spec/DD/20100524/DI" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:activiti="http://activiti.org/bpmn" id="Definitions_1" targetNamespace="http://bpmn.io/schema/bpmn">
          <bpmn:process id="Process_1" isExecutable="false">
            <bpmn:startEvent id="Event_025i5vg" name="开始节点">
              <bpmn:outgoing>Flow_0lqu3h8</bpmn:outgoing>
            </bpmn:startEvent>
            <bpmn:task id="Activity_131130w">
              <bpmn:incoming>Flow_0lqu3h8</bpmn:incoming>
            </bpmn:task>
            <bpmn:sequenceFlow id="Flow_0lqu3h8" sourceRef="Event_025i5vg" targetRef="Activity_131130w" />
          </bpmn:process>
          <bpmndi:BPMNDiagram id="BPMNDiagram_Process_1">
            <bpmndi:BPMNPlane id="BPMNPlane_Process_1" bpmnElement="Process_1">
              <bpmndi:BPMNEdge id="Flow_0lqu3h8_di" bpmnElement="Flow_0lqu3h8">
                <di:waypoint x="98" y="160" />
                <di:waypoint x="150" y="160" />
              </bpmndi:BPMNEdge>
              <bpmndi:BPMNShape id="Event_025i5vg_di" bpmnElement="Event_025i5vg">
                <dc:Bounds x="62" y="142" width="36" height="36" />
                <bpmndi:BPMNLabel>
                  <dc:Bounds x="59" y="185" width="43" height="14" />
                </bpmndi:BPMNLabel>
              </bpmndi:BPMNShape>
              <bpmndi:BPMNShape id="Activity_131130w_di" bpmnElement="Activity_131130w">
                <dc:Bounds x="150" y="120" width="100" height="80" />
              </bpmndi:BPMNShape>
            </bpmndi:BPMNPlane>
          </bpmndi:BPMNDiagram>
        </bpmn:definitions>`
      if (!this.isEdit) { // 新建时，按照节点的类型，赋值默认数据
        setData()
      } else { // 编辑时，从接口取数据，处理数据源赋值nodeList
        const list = ['bpmn:StartEvent', 'bpmn:Task', 'bpmn:SubProcess', 'bpmn:EndEvent']
        const params = {
          configId: this.configId
        }
        this.axios.get(api.getFlowNodeInfo, { params: params }).then(res => {
          if (res.data.code === 0) {
            const resData = res.data.data
            if (resData.camundaFile) { // 如果接口bpmn xml存在，那么引用它，否则用默认xml
              that.XMLString = resData.camundaFile
            }
            if (resData.flowNodeList && resData.flowNodeList.length) {
              this.nodeList = resData.flowNodeList.map(item => { // 赋值给本地节点列表
                // 所有节点都有要处理的数据：节点处理人、节点类型
                item.handlerText = ''
                item.handlerTextEllipsis = ''
                if (item.handler && item.handler.length > 0) {
                  for (let i = 0; i < item.handler.length; i++) {
                    const ele = item.handler[i]
                    item.handlerText += ele.name
                    if (i < item.handler.length - 1) {
                      item.handlerText += ','
                    }
                  }
                  if (item.handlerText.length > 22) {
                    item.handlerTextEllipsis = item.handlerText.slice(0, 22) + '...'
                  } else {
                    item.handlerTextEllipsis = item.handlerText
                  }
                }
                item.type = list[item.nodeType]
                if (item.type === 'bpmn:Task') { // 活动节点处理抄送人数据
                  if (item.cclist && item.cclist.length > 0) {
                    item.cclistText = ''
                    item.cclistTextEllipsis = ''
                    for (let i = 0; i < item.cclist.length; i++) {
                      const ele = item.cclist[i]
                      item.cclistText += ele.name
                      if (i < item.cclist.length - 1) {
                        item.cclistText += ','
                      }
                    }
                    if (item.cclistText.length > 22) {
                      item.cclistTextEllipsis = item.cclistText.slice(0, 22) + '...'
                    } else {
                      item.cclistTextEllipsis = item.cclistText
                    }
                  }
                }
                // if (item.type === 'bpmn:SubProcess') { // 子流程，处理数据传递
                //   // 因为存在保存/取消操作，dataSendList用在view，mapping用于保存后储存
                //   if (item.mapping && item.mapping.length) {
                //     item.dataSendList = item.mapping.map(item => {
                //       const obj = {}
                //       for (const key in item) {
                //         obj[key] = item[key]
                //       }
                //       return obj
                //     })
                //   }
                // }
                return item
              })
              setNewFlowFlag = false
            } else {
              setNewFlowFlag = true
            }
            setData()
          }
          this.loading = false
        }, rej => {
          this.loading = false
          console.log(rej)
        }).catch(err => {
          this.loading = false
          console.log(err)
        })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.bpmn-builder
  width 100%
  height 100%
  // background #fff
  background #1e1e23
  position relative
  .function-panel
    position absolute
    bottom 10px
    left 10px
    color #fff
</style>
