import { assign } from 'min-dash'
export default function ContextPadProvider (config, injector, contextPad, modeling, elementFactory, connect, create, translate) {
  this.modeling = modeling
  this.elementFactory = elementFactory
  this.connect = connect
  this.create = create
  this.translate = translate
  config = config || {}
  if (config.autoPlace !== false) {
    this.autoPlace = injector.get('autoPlace', false)
  }
  contextPad.registerProvider(this)
}
ContextPadProvider.$inject = [
  'config.contextPad',
  'injector',
  'contextPad',
  'modeling',
  'elementFactory',
  'connect',
  'create',
  'translate'
]
ContextPadProvider.prototype.getContextPadEntries = function (element) {
  const { autoPlace, create, elementFactory, translate, modeling, connect } = this
  function appendAction (type, className, title, options) {
    function appendStart (event, element) {
      var shape = elementFactory.createShape(assign({ type: type }, options))
      create.start(event, shape, { source: element })
    }
    var append = autoPlace ? function (event, element) {
      var shape = elementFactory.createShape(assign({ type: type }, options)); autoPlace.append(element, shape)
    } : appendStart
    return {
      group: 'model',
      className: className,
      title: title,
      action: { click: append }
      // action: { dragstart: appendStart, click: append }
    }
  }
  function removeElement (e) {
    modeling.removeElements([element])
  }
  function startConnect (event, element) {
    connect.start(event, element)
  }
  var actions = {}
  if (
    [
      'bpmn:StartEvent',
      'bpmn:Task',
      'bpmn:ParallelGateway',
      'bpmn:ExclusiveGateway',
      'bpmn:SubProcess'
    ].indexOf(element.type) !== -1) {
    assign(actions, {
      'append.append-task': appendAction(
        'bpmn:Task',
        'bpmn-icon-task',
        translate('Append Task')
      ),
      'append.SubProcess': appendAction(
        'bpmn:SubProcess',
        'bpmn-icon-subprocess-collapsed',
        translate('Append SubProcess')
      ),
      'append.ParallelGateway': appendAction(
        'bpmn:ParallelGateway',
        'bpmn-icon-gateway-parallel',
        translate('Append Parallel Gateway')
      ),
      // 'append.ExclusiveGateway': appendAction(
      //   'bpmn:ExclusiveGateway',
      //   'bpmn-icon-gateway-xor',
      //   translate('Append Exclusive Gateway')
      // ),
      // 'append.end-event': appendAction(
      //   'bpmn:EndEvent',
      //   'bpmn-icon-end-event-none',
      //   translate('Append EndEvent')
      // ),
      connect: {
        group: 'model',
        className: 'bpmn-icon-connection-multi',
        title: translate('Connect'),
        action: {
          click: startConnect,
          dragstart: startConnect
        }
      }
      // 'append.intermediate-event': appendAction(
      //   'bpmn:IntermediateThrowEvent',
      //   'bpmn-icon-intermediate-event-none',
      //   translate('Append Intermediate/Boundary Event')
      // )
    })
  }
  if (element.type !== 'bpmn:StartEvent') {
    assign(actions, {
      delete: {
        group: 'edit',
        className: 'bpmn-icon-trash',
        title: translate('Remove'),
        action: { click: removeElement }
      }
    })
    if (element.type !== 'bpmn:EndEvent') {
      assign(actions, {
        'append.end-event': appendAction(
          'bpmn:EndEvent',
          'bpmn-icon-end-event-none',
          translate('Append EndEvent')
        )
      })
    }
  }
  return actions
}
